import { API_BASE_URL } from "@/config";
import { getToken } from "@/supabase";
import * as Sentry from "@sentry/browser";

interface RequestOptions extends RequestInit {
  params?: Record<string, string>;
}

class ApiError extends Error {
  constructor(
    public status: number,
    message: string,
  ) {
    super(message);
    this.name = "ApiError";
  }
}

async function request<T>(
  url: string,
  options: RequestOptions = {},
  retries: number = 3,
  delay: number = 1000,
): Promise<T> {
  const { params, ...restOptions } = options;
  const token = getToken();
  const headers = new Headers(restOptions.headers);

  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }

  if (restOptions.body) {
    headers.set("Content-Type", "application/json");
  }

  const queryParams = params
    ? `?${new URLSearchParams(params).toString()}`
    : "";

  const fullUrl = `${API_BASE_URL}${url}${queryParams}`;

  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await fetch(fullUrl, {
        ...restOptions,
        headers,
      });

      if (!response.ok) {
        throw new ApiError(response.status, await response.text());
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        return (await response.json()) as T;
      } else {
        return (await response.text()) as unknown as T;
      }
    } catch (error) {
      if (attempt < retries - 1) {
        Sentry.addBreadcrumb({
          category: "http",
          message: "Retrying request: " + (error as Error).message,
          level: "info",
        });
        await new Promise((res) => setTimeout(res, delay));
      } else {
        Sentry.captureException(error);
        if (error instanceof ApiError) {
          throw error;
        }
        throw new Error(`Network error: ${(error as Error).message}`);
      }
    }
  }

  throw new Error("Request failed after maximum retries");
}

export const api = {
  get: <T>(url: string, options?: RequestOptions) =>
    request<T>(url, { ...options, method: "GET" }),

  post: <T>(url: string, body: unknown, options?: RequestOptions) =>
    request<T>(url, { ...options, method: "POST", body: JSON.stringify(body) }),

  put: <T>(url: string, body: unknown, options?: RequestOptions) =>
    request<T>(url, { ...options, method: "PUT", body: JSON.stringify(body) }),

  delete: <T>(url: string, options?: RequestOptions) =>
    request<T>(url, { ...options, method: "DELETE" }),

  patch: <T>(url: string, body: unknown, options?: RequestOptions) =>
    request<T>(url, {
      ...options,
      method: "PATCH",
      body: JSON.stringify(body),
    }),
};
