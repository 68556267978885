import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Box, CreateBox, FetchBoxesResponse, UpdateBox } from "./types.ts";
import { keyBy } from "lodash";
import { BoxUpdate } from "@/boxes/api.ts";

export interface BoxState {
  boxes: Record<string, Box>;
  currentBox: Box | null;
  loading: boolean;
  error: string | null;
  totalCount: number;
  currentPage: number;
  searchTerm: string;
}

const initialState: BoxState = {
  boxes: {},
  currentBox: null,
  loading: false,
  error: null,
  totalCount: 0,
  currentPage: 1,
  searchTerm: "",
};

// Slice
const boxSlice = createSlice({
  name: "box",
  initialState,
  reducers: {
    setBoxes: (state, action: PayloadAction<Box[]>) => {
      state.boxes = keyBy(action.payload, "id");
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    fetchBoxesStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchBoxesSuccess: (state, action: PayloadAction<FetchBoxesResponse>) => {
      state.boxes = keyBy(action.payload.boxes, "id");
      state.totalCount = action.payload.total;
      state.loading = false;
    },
    fetchBoxesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchBoxStart: (state, _action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
    fetchBoxSuccess: (state, action: PayloadAction<Box>) => {
      state.currentBox = action.payload;
      state.loading = false;
    },
    fetchBoxFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createBoxStart: (state, _action: PayloadAction<CreateBox>) => {
      state.loading = true;
      state.error = null;
    },
    createBoxSuccess: (state, action: PayloadAction<Box>) => {
      state.boxes[action.payload.id] = action.payload;
      state.loading = false;
    },
    createBoxFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateBoxStart: (state, _action: PayloadAction<UpdateBox>) => {
      state.loading = true;
      state.error = null;
    },
    updateBoxSuccess: (state, action: PayloadAction<Box>) => {
      state.boxes[action.payload.id] = action.payload;
      state.loading = false;
    },
    updateBoxFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteBoxStart: (state, action: PayloadAction<string>) => {
      delete state.boxes[action.payload];
      state.loading = true;
      state.error = null;
    },
    deleteBoxSuccess: (state, action: PayloadAction<string>) => {
      if (state.boxes[action.payload]) {
        delete state.boxes[action.payload];
      }
      state.loading = false;
    },
    deleteBoxFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    searchBoxesStart: (
      state,
      action: PayloadAction<{ searchTerm: string; page: number }>,
    ) => {
      state.loading = true;
      state.error = null;
      state.searchTerm = action.payload.searchTerm;
      state.currentPage = action.payload.page;
    },
    searchBoxesSuccess: (
      state,
      action: PayloadAction<{ boxes: Box[]; totalCount: number }>,
    ) => {
      state.boxes = keyBy(action.payload.boxes, "id");
      state.totalCount = action.payload.totalCount;
      state.loading = false;
    },
    searchBoxesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const createBoxRequest = createAction<{
  name: string;
  description: string;
  pictureId?: string;
}>("boxes/createBoxRequest");

export const updateBoxRequest = createAction<BoxUpdate>(
  "boxes/updateBoxRequest",
);

const thisSlice = (state: { boxes: BoxState }) => state.boxes;

export const selectAllBoxes = (state: { boxes: BoxState }) => state.boxes.boxes;

export const selectListingPage = createSelector(thisSlice, (state) => {
  return {
    boxes: Object.values(state.boxes),
    loading: state.loading,
    error: state.error,
    totalCount: state.totalCount,
    currentPage: state.currentPage,
    searchTerm: state.searchTerm,
  };
});

export const selectLoadingError = createSelector(thisSlice, (state) => ({
  loading: state.loading,
  error: state.error,
}));

export const selectCurrentBox = (state: { boxes: BoxState }) =>
  state.boxes.currentBox;

export const selectSearchTerm = (state: { boxes: BoxState }) =>
  state.boxes.searchTerm;

export const selectMatchingBoxes = createSelector(
  thisSlice,
  selectSearchTerm,
  (state, searchTerm) => {
    if (!searchTerm) {
      return [];
    }
    return Object.values(state.boxes).filter((box) =>
      box.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  },
);

export const selectBoxById = (state: { boxes: BoxState }, id: string) =>
  state.boxes.boxes[id];

// Actions
export const {
  setBoxes,
  setSearchTerm,
  fetchBoxesStart,
  fetchBoxesSuccess,
  fetchBoxesFailure,
  fetchBoxStart,
  fetchBoxSuccess,
  fetchBoxFailure,
  createBoxStart,
  createBoxSuccess,
  createBoxFailure,
  updateBoxStart,
  updateBoxSuccess,
  updateBoxFailure,
  deleteBoxStart,
  deleteBoxSuccess,
  deleteBoxFailure,
  searchBoxesStart,
  searchBoxesSuccess,
  searchBoxesFailure,
} = boxSlice.actions;

// Reducer
export default boxSlice.reducer;
