import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  deleteBoxStart,
  fetchBoxStart,
  selectCurrentBox,
  selectLoadingError,
} from "./boxesSlice.ts";
import { pictureUrl } from "@/pictures/utils.ts";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import ItemsListRow from "@/items/components/ItemsListRow.tsx";
import { RootState } from "@/store.ts";
import { Item } from "@/items/types.ts";
import { addItemToBoxRequest, selectItemsForBox } from "@/items/itemsSlice.ts";
import AddItemPopover from "@/boxes/components/AddItemPopover.tsx";

const BoxDetailPage: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { loading, error } = useSelector(selectLoadingError);
  const currentBox = useSelector(selectCurrentBox);
  const items = useSelector<RootState, Item[]>((state) =>
    selectItemsForBox(state, id),
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchBoxStart(id));
      // dispatch(fetchBoxWithItemsStart(id));
      // dispatch(fetchBoxWithPicturesStart(id));
    }
  }, [dispatch, id]);

  const handleDelete = () => {
    if (id && window.confirm("Are you sure you want to delete this box?")) {
      dispatch(deleteBoxStart(id));
    }
  };

  if (loading) return <div className="container mx-auto p-4">Loading...</div>;
  if (error) return <div className="container mx-auto p-4">Error: {error}</div>;
  if (!currentBox) return null;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">{currentBox.name}</h1>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Box Details
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {currentBox.description}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created At</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {new Date(currentBox.createdAt).toLocaleString()}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Updated At</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {new Date(currentBox.updatedAt).toLocaleString()}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <AddItemPopover
        onSubmit={({ itemId }) =>
          dispatch(addItemToBoxRequest({ id: itemId, boxId: id }))
        }
      />

      <Table className={"text-left"}>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Box</TableHead>
            <TableHead>Picture(s)?</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item) => (
            <ItemsListRow id={item.id} key={item.id} />
          ))}
        </TableBody>
      </Table>

      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2">Pictures</h2>
        {currentBox.pictures && currentBox.pictures.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {currentBox.pictures.map((picture) => (
              <img
                key={picture.id}
                src={pictureUrl(picture)}
                alt={picture.description || "Box picture"}
                className="w-full h-auto max-h-96 object-contain mb-4 rounded-t-lg"
              />
            ))}
          </div>
        ) : (
          <p>No pictures for this box.</p>
        )}
      </div>

      <div className="flex space-x-4">
        <Link
          to={`/boxes/${id}/edit`}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Edit Box
        </Link>
        <button
          onClick={handleDelete}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Delete Box
        </button>
      </div>
    </div>
  );
};

export default BoxDetailPage;
