import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Link } from "react-router-dom";

const NavMenu = () => {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <Link to="/review-queue" className={navigationMenuTriggerStyle()}>
            Review Queue
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link to="/tag-search" className={navigationMenuTriggerStyle()}>
            Tag Search
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link to="/pictures" className={navigationMenuTriggerStyle()}>
            Pictures
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link to="/items" className={navigationMenuTriggerStyle()}>
            Items
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <Link to="/boxes" className={navigationMenuTriggerStyle()}>
            Boxes
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default NavMenu;
