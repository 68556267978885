import { createBrowserRouter, Outlet } from "react-router-dom";
import ListPictures from "./pictures/ListPictures.tsx";
import NewPicture from "./pictures/NewPicture.tsx";
import PictureDetailPage from "./pictures/PictureDetailPage.tsx";
import TagSearchPage from "./tags/TagSearchPage.tsx";
import ItemsList from "./items/ItemsList.tsx";
import CreateItem from "./items/CreateItem.tsx";
import ItemDetail from "./items/ItemDetail.tsx";
import EditItemPage from "./items/EditItemPage.tsx";
import BoxListingPage from "./boxes/BoxListingPage.tsx";
import CreateBoxPage from "./boxes/CreateBoxPage.tsx";
import EditBoxPage from "./boxes/EditBoxPage.tsx";
import BoxDetailPage from "./boxes/BoxDetailPage.tsx";
import NavMenu from "@/components/NavMenu.tsx";
import ReviewQueuePage from "@/review-queue/ReviewQueuePage.tsx";

const routes = [
  {
    path: "",
    element: <ListPictures />,
  },
  {
    path: "pictures",
    element: <ListPictures />,
  },
  {
    path: "pictures/new",
    element: <NewPicture />,
  },
  {
    path: "pictures/:id",
    element: <PictureDetailPage />,
  },
  {
    path: "tag-search",
    element: <TagSearchPage />,
  },
  {
    path: "items",
    element: <ItemsList />,
  },
  {
    path: "items/new",
    element: <CreateItem />,
  },
  {
    path: "items/:id",
    element: <ItemDetail />,
  },
  {
    path: "items/:id/edit",
    element: <EditItemPage />,
  },
  {
    path: "boxes",
    element: <BoxListingPage />,
  },
  {
    path: "boxes/new",
    element: <CreateBoxPage />,
  },
  {
    path: "boxes/:id/edit",
    element: <EditBoxPage />,
  },
  {
    path: "boxes/:id",
    element: <BoxDetailPage />,
  },
  {
    path: "review-queue",
    element: <ReviewQueuePage />,
  },
];

function Root() {
  return (
    <>
      <NavMenu />
      <Outlet />
    </>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: routes,
  },
]);

export default router;
