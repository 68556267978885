import { api } from "@/api.ts";
import { Box } from "@/boxes/types.ts";
import { ApiPicture, mapPicture } from "@/pictures/api.ts";
import { map } from "lodash";

export interface ApiBox {
  id: string;
  name: string;
  description: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  pictures: ApiPicture[];
}

export interface ApiBoxCreate {
  name: string;
  description?: string;
  picture_id?: string;
}

interface PaginatedBoxesResponse {
  boxes: ApiBox[];
  total: number;
}

export interface BoxCreate {
  name: string;
  description?: string;
  pictureId?: string;
}

export interface BoxUpdate {
  id: string;
  name?: string;
  description?: string;
  pictureId?: string;
}

export function mapBox(box: ApiBox): Box {
  return {
    id: box.id,
    name: box.name,
    description: box.description,
    userId: box.user_id,
    createdAt: box.created_at,
    updatedAt: box.updated_at,
    pictures: map(box.pictures, mapPicture),
  };
}

export function unmapBoxCreate(box: BoxCreate): ApiBoxCreate {
  return {
    name: box.name,
    description: box.description,
    picture_id: box.pictureId,
  };
}

interface ApiBoxUpdate {
  id: string;
  name?: string;
  description?: string;
  picture_id?: string;
}

export function unmapBoxUpdate(box: BoxUpdate): ApiBoxUpdate {
  return {
    id: box.id,
    name: box.name,
    description: box.description,
    picture_id: box.pictureId,
  };
}

export interface PaginatedBoxes {
  boxes: Box[];
  total: number;
}

export const boxApi = {
  fetchAllBoxes: async (): Promise<Box[]> => {
    const boxes = await api.get<ApiBox[]>("/boxes/all");
    return boxes.map(mapBox);
  },
  fetchBoxes: async (skip = 0, limit = 100): Promise<PaginatedBoxes> => {
    const response = await api.get<PaginatedBoxesResponse>("/boxes", {
      params: { skip: skip.toString(), limit: limit.toString() },
    });
    return {
      boxes: response.boxes.map(mapBox),
      total: response.total,
    };
  },

  fetchBox: async (boxId: string): Promise<Box> => {
    const response = await api.get<ApiBox>(`/boxes/${boxId}`);
    return mapBox(response);
  },

  createBox: async (box: BoxCreate): Promise<Box> => {
    const response = await api.post<ApiBox>("/boxes", unmapBoxCreate(box));
    return mapBox(response);
  },

  updateBox: async (boxId: string, boxUpdate: BoxUpdate): Promise<Box> => {
    const response = await api.put<ApiBox>(
      `/boxes/${boxId}`,
      unmapBoxUpdate(boxUpdate),
    );
    return mapBox(response);
  },

  deleteBox: (boxId: string): Promise<{ success: boolean; message: string }> =>
    api.delete<{ success: boolean; message: string }>(`/boxes/${boxId}`),

  searchBoxes: async (name: string): Promise<Box[]> => {
    const response = await api.get<ApiBox[]>("/boxes/search", {
      params: { name },
    });
    return response.map(mapBox);
  },
};
